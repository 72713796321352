import { useEffect, useState } from 'react';
import AppUserManager from '../AppUserManager';
import axios from 'axios';
// import { useLocation, useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { Breadcrumbs, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { Tabs, Tab } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TabPanel from '../components/TabPanel';

const TabSubscriptionList = () => {
    const [subscriptionData, setSubscriptionData] = useState(null);
    useEffect(() => {
        AppUserManager.getUser().then((user) => {
            axios.get(process.env.REACT_APP_API_BASE + '/service/c480b0c4-e2c8-404a-848f-ed8724b4085f/subscription', {
                    'headers': {
                        'Authorization': 'Bearer ' + user.access_token
                    }
                }
            ).then((response) => {
                setSubscriptionData(response.data);
            });
        });
    }, []);
    return (
        <SubscriptionListTable rows={subscriptionData}/>
    )
}


const SubscriptionListTable = ({rows}) => {
    if (!rows) return null;
    return(
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell align="right">Purchased</TableCell>
                    <TableCell align="right">Assigned</TableCell>
                    <TableCell align="right">Unassigned</TableCell>
                    <TableCell align="right">Allocated</TableCell>
                    <TableCell align="right">Unallocated</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                    {rows.subscriptions.map( (d, idx) => {
                        return (
                            <TableRow key={d.id}>
                                <TableCell><RouterLink to={"/subscriptions/" + d.id} 
                                                       state={{ 
                                                            subscription_name: d.name 
                                                        }} 
                                                        style={{ textDecoration: 'none' }}
                                            >{d.name}</RouterLink></TableCell>
                                <TableCell align="right">{d.license_qty}</TableCell>
                                <TableCell align="right">{d.assigned_licenses}</TableCell>
                                <TableCell align="right">{d.unassigned_licenses}</TableCell>
                                <TableCell align="right">{d.allocated_licenses}</TableCell>
                                <TableCell align="right">{d.unallocated_licenses}</TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

const SubscriptionListPage = () => {
    // const location = useLocation();
    const [value, setValue] = useState(0);
    // const params = useParams();

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    return (
        <Box>
            <Breadcrumbs aria-label="breadcrumb">
                <Typography color="text.primary">Subscriptions</Typography>
            </Breadcrumbs>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Subscriptions" />
            </Tabs>

            <TabPanel value={value} index={0}>
                <TabSubscriptionList/>
            </TabPanel>
        </Box>
    )
}

export default SubscriptionListPage;