import { ThemeProvider, createTheme } from '@mui/material/styles';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Layout from "./components/Layout"
import AppUserManager from "./AppUserManager"
import {AuthProvider} from "oidc-react";
import './App.css';
import OrganizationListPage from './pages/OrganizationListPage';
import CorporateListPage from './pages/CorporateListPage';
import OrganizationSubscriptionsPage from './pages/OrganizationSubscriptionsPage';
import LicensePoolPage from './pages/LicensePoolPage';
import SubscriptionListPage from './pages/SubscriptionListPage';
import SubscriptionViewPage from './pages/SubscriptionViewPage';

const theme = createTheme();

AppUserManager.events.addAccessTokenExpired(function(){
    AppUserManager.signoutRedirect();
});

function App() {
  return (
      <AuthProvider
          userManager={AppUserManager}>
          <ThemeProvider theme={theme}>
          <Router>
              <Layout>
                  <Routes>
                    <Route exact path="/" element={<OrganizationListPage/>}/>
                    <Route exact path="/organizations" element={<OrganizationListPage/>}/>
                    <Route exact path="/organizations-corporate" element={<CorporateListPage/>}/>
                    <Route path="/organizations/:id/subscriptions" element={<OrganizationSubscriptionsPage/>}/>
                    <Route path="/license-pools/:id" element={<LicensePoolPage/>}/>
                    <Route path="/subscriptions" element={<SubscriptionListPage/>}/>
                    <Route path="/subscriptions/:id" element={<SubscriptionViewPage/>}/>
                  </Routes>
              </Layout>
          </Router>
          </ThemeProvider>
      </AuthProvider>
  );
}

export default App;
