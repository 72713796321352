import { useEffect, useState } from 'react';
import AppUserManager from '../AppUserManager';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/system';
import { Breadcrumbs, Typography, Link } from '@mui/material';
import { Tabs, Tab } from '@mui/material';
import LicensePoolTable from '../components/LicensePoolTable';
import ActivityLogTable from '../components/ActivityLogTable';
import TabPanel from '../components/TabPanel';

const TabLicensePools = ({OrganizationalUnitId}) => {
    const [licensePoolData, setLicensePoolData] = useState(null);
    useEffect(() => {
        AppUserManager.getUser().then((user) => {
            axios.get(process.env.REACT_APP_API_BASE + '/organizational-unit/' + OrganizationalUnitId + '/license-pools', {
                    'headers': {
                        'Authorization': 'Bearer ' + user.access_token
                    }
                }
            ).then((response) => {
                const LPData = response.data.license_pools.map( d => {
                    return {
                        "id": d.id,
                        "name": d.license_name,
                        "allocated_licenses": d.allocated_licenses,
                        "assigned_licenses": d.assigned_licenses,
                        "available_licenses": d.available_licenses,
                        "to": "/license-pools/" + d.id,
                        "state": {
                            "ou_name": d.organizational_unit_name,
                            "ou_id": d.ou_id,
                            "license_name": d.license_name
                        }
                    }
                })
                setLicensePoolData(LPData);
            });
        });
    }, [OrganizationalUnitId]);

    return (
        <LicensePoolTable rows={licensePoolData}/>
    )
}

const TabActivityLog = ({OrganizationalUnitId}) => {
    const [activityLogData, setActivityLogData] = useState(null);
    useEffect(() => {
        AppUserManager.getUser().then((user) => {
            axios.get(process.env.REACT_APP_API_BASE + '/organizational-unit/' + OrganizationalUnitId + '/audit-log', {
                    'headers': {
                        'Authorization': 'Bearer ' + user.access_token
                    }
                }
            ).then((response) => {
                setActivityLogData(response.data);
            });
        });
    }, [OrganizationalUnitId]);
    return (
        <ActivityLogTable rows={activityLogData}/>
    )
}

const OrganizationSubscriptionsPage = () => {
    const params = useParams();
    const [organizationData, setOrganizationData] = useState({});
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    useEffect(() => {
        AppUserManager.getUser().then((user) => {
            axios.get(process.env.REACT_APP_API_BASE + '/organizational-unit/' + params.id, {
                    'headers': {
                        'Authorization': 'Bearer ' + user.access_token
                    }
                }
            ).then((response) => {
                setOrganizationData(response.data);
            });
        });
    }, [params.id]);

    return(
        <Box>
            <Breadcrumbs aria-label="breadcrumb">
                <Link
                    underline="hover"
                    color="inherit"
                    href="/"
                    >
                    Organizations
                </Link>
                <Typography color="text.primary">{organizationData.name}</Typography>
            </Breadcrumbs>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="License Pools" />
                <Tab label="Activity Log"  />
            </Tabs>

            <TabPanel value={value} index={0}>
                <TabLicensePools OrganizationalUnitId={params.id}/>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <TabActivityLog OrganizationalUnitId={params.id}/>
            </TabPanel>
        </Box>
    )
}

export default OrganizationSubscriptionsPage;