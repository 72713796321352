import { List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { useEffect, useState } from 'react';
import AppUserManager from '../AppUserManager';
import axios from 'axios';
import { Link } from 'react-router-dom';

const OrganizationList = () => {
  const [data, setData] = useState({organizational_units: []});

  useEffect(() => {
    AppUserManager.getUser().then((user) => {
        axios.get(process.env.REACT_APP_API_BASE + '/organizational-unit?depth=2', {
                'headers': {
                    'Authorization': 'Bearer ' + user.access_token
                }
            }
        ).then((response) => {
            setData(response.data);
        });
    });
}, []);

if (!data) return null;

  return (
    <List>
      {data.organizational_units.map( (d, idx) => {
        return(
          d.license_pools_count ? 
            <ListItem disablePadding key={d.id}>
              <Link to={"/organizations/" + d.id + "/subscriptions"} style={{ textDecoration: 'none' }}>
                <ListItemButton>
                  <ListItemText primary={d.name} secondary={d.type} />
                </ListItemButton>
              </Link>
            </ListItem>
          : null
        )
      })}
    </List>
  )
}
export default function OrganizationListPage() {
    return(
        <OrganizationList/>
    )
}