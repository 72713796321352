import { useEffect, useState } from 'react';
import AppUserManager from '../AppUserManager';
import axios from 'axios';
import { useLocation, useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { Breadcrumbs, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { Tabs, Tab } from '@mui/material';
import TabPanel from '../components/TabPanel';
import LicensePoolTable from '../components/LicensePoolTable';

const TabLicensePools = ({subscriptionId}) => {
    const [licensePoolData, setLicensePoolData] = useState(null);
    useEffect(() => {
        AppUserManager.getUser().then((user) => {
            axios.get(process.env.REACT_APP_API_BASE + '/subscription/' + subscriptionId + '/license-pools?per_page=100', {
                    'headers': {
                        'Authorization': 'Bearer ' + user.access_token
                    }
                }
            ).then((response) => {
                const LPData = response.data.license_pools.map( d => {
                    return {
                        "id": d.id,
                        "name": d.organizational_unit_name,
                        "allocated_licenses": d.allocated_licenses,
                        "assigned_licenses": d.assigned_licenses,
                        "available_licenses": d.available_licenses,
                        "to": "/license-pools/" + d.id,
                        "state": {
                            "ou_name": d.organizational_unit_name,
                            "license_name": d.license_name,
                            "license_id": d.id
                        }
                    }
                })
                setLicensePoolData(LPData);
            });
        });
    }, [subscriptionId]);
    return (
        <LicensePoolTable rows={licensePoolData}/>
    )
}

const SubscriptionViewPage = () => {
    const location = useLocation();
    const [value, setValue] = useState(0);
    const params = useParams();

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    return (
        <Box>
            <Breadcrumbs aria-label="breadcrumb">
                <Link
                    underline="hover"
                    color="inherit"
                    to="/subscriptions"
                    >
                    Subscriptions
                </Link>
                <Typography color="text.primary">{location.state.subscription_name}</Typography>
            </Breadcrumbs>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="License Pools" />
            </Tabs>

            <TabPanel value={value} index={0}>
                <TabLicensePools subscriptionId={params.id}/>
            </TabPanel>
        </Box>

    )
}

export default SubscriptionViewPage;