import { List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { useEffect, useState } from 'react';
import AppUserManager from '../AppUserManager';
import axios from 'axios';
import { Link } from 'react-router-dom';

const CorporateList = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    AppUserManager.getUser().then((user) => {
        axios.get(process.env.REACT_APP_API_BASE + '/organizational-unit/1979cc29-675c-4326-9ab1-a606414d3111', {
                'headers': {
                    'Authorization': 'Bearer ' + user.access_token
                }
            }
        ).then((response) => {
            setData(response.data);
        });
    });
  }, []);

  if (!data) return null;

  return (
    <List>
      {data.children.map( (d, idx) => {
        return(
          <ListItem disablePadding key={d.id}>
            <Link to={"/organizations/" + d.id + "/subscriptions"} style={{ textDecoration: 'none' }}>
              <ListItemButton>
                <ListItemText primary={d.name} secondary={d.type} />
              </ListItemButton>
            </Link>
          </ListItem>
        )
      })}
    </List>
  )
}

export default function CorporateListPage() {
    return(
        <CorporateList/>
    )
}