import { useEffect, useState } from 'react';
import AppUserManager from '../AppUserManager';
import axios from 'axios';
import { useLocation, useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { Breadcrumbs, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { Tabs, Tab } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ActivityLogTable from '../components/ActivityLogTable';
import TabPanel from '../components/TabPanel';

const TabLicenseDetails = ({licensePoolId}) => {
    const [licenseData, setLicenseData] = useState(null);
    useEffect(() => {
        AppUserManager.getUser().then((user) => {
            axios.get(process.env.REACT_APP_API_BASE + '/license-pool/' + licensePoolId + '/licenses?per_page=100', {
                    'headers': {
                        'Authorization': 'Bearer ' + user.access_token
                    }
                }
            ).then((response) => {
                setLicenseData(response.data);
            });
        });
    }, [licensePoolId]);
    return (
        <LicenseDetailsTable rows={licenseData}/>
    )
}

const TabActivityLog = ({licensePoolId}) => {
    const [activityLogData, setActivityLogData] = useState(null);
    useEffect(() => {
        AppUserManager.getUser().then((user) => {
            axios.get(process.env.REACT_APP_API_BASE + '/license-pool/' + licensePoolId + '/audit-log', {
                    'headers': {
                        'Authorization': 'Bearer ' + user.access_token
                    }
                }
            ).then((response) => {
                setActivityLogData(response.data);
            });
        });
    }, [licensePoolId]);
    return (
        <ActivityLogTable rows={activityLogData}/>
    )
}

const LicenseDetailsTable = ({rows}) => {
    if (!rows) return null;
    return(
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                <TableRow>
                    <TableCell>Assigned To</TableCell>
                    <TableCell align="right">Date</TableCell>
                    <TableCell align="right">By</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                    {rows.licenses.map( (d, idx) => {
                        return (
                            <TableRow key={d.id}>
                                <TableCell>{d.assignee_details.userPrincipalName}</TableCell>
                                <TableCell align="right">{d.assigned_date}</TableCell>
                                <TableCell align="right">{d.assigned_by}</TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

const LicensePoolPage = () => {
    const location = useLocation();
    const [value, setValue] = useState(0);
    const params = useParams();

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    return (
        <Box>
            <Breadcrumbs aria-label="breadcrumb">
                <Link
                    underline="hover"
                    color="inherit"
                    to="/"
                    >
                    Organizations
                </Link>
                <Link
                    underline="hover"
                    color="inherit"
                    to={"/organizations/" + location.state.ou_id + "/subscriptions"}
                    >
                    {location.state.ou_name}
                </Link>
                <Typography color="text.primary">{location.state.license_name}</Typography>
            </Breadcrumbs>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Licenses" />
                <Tab label="Activity Log"  />
            </Tabs>

            <TabPanel value={value} index={0}>
                <TabLicenseDetails licensePoolId={params.id}/>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <TabActivityLog licensePoolId={params.id}/>
            </TabPanel>
        </Box>

    )
}

export default LicensePoolPage;