import React, {useState} from 'react'
import Typography from "@mui/material/Typography";
import {AppBar, Toolbar, Box} from "@mui/material";
import { useAuth } from 'oidc-react';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { NavLink as RouterNavLink } from "react-router-dom";
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';


export default function Layout( { children }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const auth = useAuth()
    const drawerWidth = 220;
    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleSignout = () => {
        auth.signOutRedirect();
    };

    if (auth.isLoading) return null;

    return (
        <Box sx={{ display: 'flex'}}>
            <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
                elevation={5}
                color="primary"
                >
                <Toolbar>
                    <Typography
                        variant="h6"
                        sx={{ color: "white", flexGrow: 1, textDecoration: "none", boxShadow: "none" }}
                        noWrap
                        component={RouterNavLink}
                        to={"/"}
                    >
                        Subscription License Manager
                    </Typography>
                    <Typography>
                        {auth.userData.profile.given_name} {auth.userData.profile.family_name.charAt(0)}.
                    </Typography>
                    <div>

                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                        >
                            <AccountCircle />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={handleSignout}>Sign out</MenuItem>
                        </Menu>
                    </div>
                </Toolbar>
            </AppBar>
            <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
          <List>
              <ListItem key={"Organizations"} disablePadding >
                <ListItemButton component={RouterNavLink} to={"/organizations"} sx={{
        '&.active': {
          backgroundColor: '#ccc',
          fontWeight: 600
        }
      }}>
                  <ListItemIcon>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Organizations"} />
                </ListItemButton>
              </ListItem>
              <ListItem key={"Corporate"} disablePadding >
                <ListItemButton component={RouterNavLink} to={"/organizations-corporate"} sx={{
        '&.active': {
          backgroundColor: '#ccc',
          fontWeight: 600
        }
      }}>
                  <ListItemIcon>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Corporate"} />
                </ListItemButton>
              </ListItem>
              <ListItem key={"Subscriptions"} disablePadding >
                <ListItemButton component={RouterNavLink} to={"/subscriptions"} sx={{
        '&.active': {
          backgroundColor: '#ccc',
          fontWeight: 600
        }
      }}>
                  <ListItemIcon>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Subscriptions"} />
                </ListItemButton>
              </ListItem>
          </List>
        </Box>
      </Drawer>
            <Box
                component="main"
                sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
            >
                <Toolbar />
                {children}
            </Box>
        </Box>
    )
}