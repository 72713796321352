import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Link as RouterLink } from 'react-router-dom';

const LicensePoolTable = ({rows}) => {
    if (!rows) return null;
    return(
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                <TableRow>
                    <TableCell>License Pool</TableCell>
                    <TableCell align="right">Allocated</TableCell>
                    <TableCell align="right">Assigned</TableCell>
                    <TableCell align="right">Unassigned</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map( (d, idx) => {
                        return (
                            <TableRow key={d.id}>
                                <TableCell><RouterLink to={d.to} 
                                                       state={d.state} 
                                                       style={{ textDecoration: 'none' }}
                                            >{d.name}</RouterLink></TableCell>
                                <TableCell align="right">{d.allocated_licenses}</TableCell>
                                <TableCell align="right">{d.assigned_licenses}</TableCell>
                                <TableCell align="right">{d.available_licenses}</TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default LicensePoolTable;